/**
 * 热门关键字相关API
 */

import request from '@/utils/request'

/**
 * 获取热门关键字
 * @param params
 */
export function getHotKeywords(params) {
  return request({
    url: 'admin/pages/hot-keywords',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 添加热门关键字
 * @param params
 */
export function addHotKeywords(params) {
  return request({
    url: 'admin/pages/hot-keywords',
    method: 'post',
    data: params,
    log: {
      type: '3',
      name: '热门关键字添加'
    }
  })
}

/**
 * 获取热门关键字详情
 * @param id
 */
export function getHotKeywordsDetail(id) {
  return request({
    url: `admin/pages/hot-keywords/${id}`,
    method: 'get'
  })
}

/**
 * 修改热门关键字
 * @param id
 * @param params
 */
export function editHotKeywords(id, params) {
  return request({
    url: `admin/pages/hot-keywords/${id}`,
    method: 'put',
    data: params,
    log: {
      type: '3',
      name: '热门关键字修改'
    }
  })
}

/**
 * 删除热门关键词
 * @param id
 */
export function deleteHotKeywords(id) {
  return request({
    url: `admin/pages/hot-keywords/${id}`,
    method: 'delete',
    log: {
      type: '3',
      name: '热门关键字删除'
    }
  })
}

/**
 * 关键字来源统计-所有搜索内容排行
 * @param params
 */
export function getHotwordpage(params) {
  return request({
    url: 'admin/statistics/hotwordpage',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 关键字来源统计-关键词排行图表
 * @param params
 */
export function getHotwordsimplechart(params) {
  return request({
    url: 'admin/statistics/hotwordsimplechart',
    method: 'get',
    loading: false,
    params
  })
}
