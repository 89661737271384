import { render, staticRenderFns } from "./hot-keywords.vue?vue&type=template&id=00ca6f38&scoped=true"
import script from "./hot-keywords.vue?vue&type=script&lang=js"
export * from "./hot-keywords.vue?vue&type=script&lang=js"
import style0 from "./hot-keywords.vue?vue&type=style&index=0&id=00ca6f38&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00ca6f38",
  null
  
)

export default component.exports